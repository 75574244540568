var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0 mt-3 mb-3",attrs:{"dismissible":_vm.ui.alert.dismissible,"icon":_vm.ui.alert.icon,"type":_vm.ui.alert.type},model:{value:(_vm.ui.alert.enable),callback:function ($$v) {_vm.$set(_vm.ui.alert, "enable", $$v)},expression:"ui.alert.enable"}},[_vm._v(" "+_vm._s(_vm.ui.alert.message)+" ")])],1),_c('v-col',{staticClass:"home",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-12 my-12 float-left hover-effect",attrs:{"elevation":hover ? 12 : 2,"color":"transparent","max-width":"374"}},[_c('v-img',{attrs:{"height":"270","contain":"","src":require("../assets/Content-pana-green.png")}}),_c('v-card-text',[_c('v-row',{staticClass:"mx-0"})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',{staticClass:"text-center d-block overline"},[_vm._v(" Nouveau produit")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text text-center","column":""}},[_c('v-chip',{attrs:{"to":{name:'GeneralProductsAdd', params: {type : 'simple'}}}},[_vm._v("Simple")]),_c('v-chip',{attrs:{"to":{name:'GeneralProductsAdd', params: {type : 'bundle'}}}},[_vm._v("Bundle")])],1)],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-12 my-12 float-left hover-effect",attrs:{"elevation":hover ? 12 : 2,"color":"transparent","max-width":"374"}},[_c('v-img',{attrs:{"height":"270","contain":"","src":require("../assets/3d_modeling.png")}}),_c('v-card-text',[_c('v-row',{staticClass:"mx-0"})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',{staticClass:"text-center d-block overline"},[_vm._v(" Nouveau configurateur")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text text-center","column":""}},_vm._l((_vm.sites),function(site){return _c('v-chip',{key:site.id,attrs:{"to":{name:'SiteConfiguratorsAdd', params: {id : site.id}}}},[_vm._v(" "+_vm._s(site.value)+" ")])}),1)],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-12 my-12 float-left",attrs:{"elevation":hover ? 12 : 2,"color":"transparent","max-width":"374"}},[_c('v-img',{attrs:{"height":"270","contain":"","src":require("../assets/Invoice-amico.png")}}),_c('v-card-text',[_c('v-row',{staticClass:"mx-0"})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',{staticClass:"text-center d-block overline"},[_vm._v(" Demandes")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text text-center","column":""}},[_c('v-chip',[_vm._v("Détails")])],1)],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-12 my-12 float-left hover-effect",attrs:{"elevation":hover ? 12 : 2,"color":"transparent","max-width":"374"}},[_c('v-img',{attrs:{"height":"270","contain":"","src":require("../assets/Filing system-amico-purple.png")}}),_c('v-card-text',[_c('v-row',{staticClass:"mx-0"})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-title',{staticClass:"text-center d-block overline"},[_vm._v(" CLIENTS")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-chip-group',{attrs:{"active-class":"deep-purple accent-4 white--text text-center","column":""}},[_c('v-chip',[_vm._v("Détails")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }