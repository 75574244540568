<template>
  <div>
    <v-row>
<!--      <v-breadcrumbs :items="breadcrumbs" class="px-4">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>-->

      <v-col cols="12">
        <v-alert
          v-model="ui.alert.enable"
          :dismissible="ui.alert.dismissible"
          :icon="ui.alert.icon"
          :type="ui.alert.type"
          class="mb-0 mt-3 mb-3"
        >
          {{ ui.alert.message }}
        </v-alert>
      </v-col>
      <v-col cols="12" class="home">
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2"
                  color="transparent"
                  class="mx-12 my-12 float-left hover-effect"
                  max-width="374"
          >
            <v-img
              height="270"
              contain
              src="../assets/Content-pana-green.png"
            ></v-img>
            <v-card-text>
              <v-row
                class="mx-0"
              >
              </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-title class="text-center d-block overline"> Nouveau produit</v-card-title>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-chip-group
                  active-class="deep-purple accent-4 white--text text-center"
                  column>
                  <v-chip :to="{name:'GeneralProductsAdd', params: {type : 'simple'}}">Simple</v-chip>
                  <v-chip :to="{name:'GeneralProductsAdd', params: {type : 'bundle'}}">Bundle</v-chip>
                </v-chip-group>

              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2"
                  color="transparent"
                  class="mx-12 my-12 float-left hover-effect"
                  max-width="374"
          >
            <v-img
              height="270"
              contain
              src="../assets/3d_modeling.png"
            ></v-img>
            <v-card-text>
              <v-row
                class="mx-0"
              >
              </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-title class="text-center d-block overline"> Nouveau configurateur</v-card-title>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-chip-group
                  active-class="deep-purple accent-4 white--text text-center"
                  column>
                  <v-chip v-for="site in sites" :key="site.id" :to="{name:'SiteConfiguratorsAdd', params: {id : site.id}}">
                    {{ site.value }}
                  </v-chip>
                  </v-chip-group>

              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2"
                  color="transparent"
                  class="mx-12 my-12 float-left"
                  max-width="374"
          >
            <v-img
              height="270"
              contain
              src="../assets/Invoice-amico.png"
            ></v-img>
            <v-card-text>
              <v-row
                class="mx-0"
              >
              </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-title class="text-center d-block overline"> Demandes</v-card-title>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-chip-group
                  active-class="deep-purple accent-4 white--text text-center"
                  column>
                  <v-chip>Détails</v-chip>
                </v-chip-group>

              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2"
                  color="transparent"
                  class="mx-12 my-12 float-left hover-effect"
                  max-width="374"
          >
            <v-img
              height="270"
              contain
              src="../assets/Filing system-amico-purple.png"
            ></v-img>
            <v-card-text>
              <v-row
                class="mx-0"
              >
              </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-title class="text-center d-block overline"> CLIENTS</v-card-title>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-chip-group
                  active-class="deep-purple accent-4 white--text text-center"
                  column>
                  <v-chip>Détails</v-chip>
                </v-chip-group>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  data: () => ({
    sites: {},
    breadcrumbs: [
      {
        text: 'Accueil',
        disabled: true,
        to: { name: 'Home' }
      }
    ],
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    }
  }),
  methods: {
    async getSites () {
      this.sites = (await this.axios.get('/site/list', {
        headers: {
          token: this.$session.get('auth_credentials').token
        }
      })).data
    }
  },
  mounted () {
    if (!this._.isUndefined(this.$router.currentRoute.params.alert)) {
      this.ui.alert = this.$router.currentRoute.params.alert
    }

    this.getSites()
  }
}
</script>

<style lang="scss">

.v-card.hover-effect {
  transition: box-shadow .4s ease-in-out!important;
}
</style>
